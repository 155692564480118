import axios from 'axios'
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
import router from '@/router/index'
/* import {
    Message
} from 'element-ui' */
const Server = axios.create({
    timeout: 10000,
    baseURL: window.baseUrl
})

Server.interceptors.request.use(req => {
    req.cancelToken = source.token;
    config =>{
    	req.cancelToken = source.token;
    	const sysUrl = window.baseUrl;
    	if (sysUrl) {
    		config.baseURL=sysUrl;
    	} 
    }
    return req
}, err => {
    return Promise.reject(err);
})
Server.interceptors.response.use(res => {
    if (res.data.code == 500) {
        console.log('系统异常，请联系管理员')
        // token过期清除所有请求
        source.cancel();
        localStorage.clear()
        // router.push('/login')
        //window.location.reload()
    }
    return res.data
}, error => {
    if (axios.isCancel(error)) { // 取消请求的情况下，终端Promise调用链
        return new Promise(() => {});
    } else {
        return Promise.reject(error);
    }
})

export default Server