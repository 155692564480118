import request from '@/utils/server'
import qs from 'qs'
// export function getCheckListInfo(checkId){
//     return request({
//         method:'GET',
//         url:'/html/inspection/getCheckListInfo/${checkId}'
//     })
// }
export default class {
    // 检查单
    static getCheckListInfo(checkId) {
        return request({
            url: '/html/inspection/getCheckListInfo/'+checkId,
            method: 'get'
        })
    }
    // 巡检单
    static getMobileInfo(ispId) {
        return request({
            url: '/html/inspection/getMobileInfo/'+ispId,
            method: 'get'
        })
    }
    // 随手拍
    static getRandomInfo(randomId) {
        return request({
            url: '/html/inspection/getRandomInfo/'+randomId,
            method: 'get'
        })
    }

	// 工人信息
	static getEmployeeInfo(idNo) {
	    return request({
	        url: '/html/employeesite/getEmployeeInfo/'+idNo,
	        method: 'get'
	    })
	}
}